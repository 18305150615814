export async function logPerformanceMetric(markOrMeasure) {
  if (
    window.DD_RUM !== undefined &&
    window.NWE_RUM?.shouldLogPerformanceTiming?.()
  ) {
    const { getShellMetrics = Promise.resolve() } = await System.import(
      '@wf-mfe/unified-shell-bootstrapper'
    )
    const shellMetrics = await getShellMetrics

    const metric =
      typeof markOrMeasure === 'string'
        ? performance.getEntriesByName?.(markOrMeasure)?.[0]
        : markOrMeasure

    if (metric) {
      let metricName = metric.name
      let metricValue

      if (metric.entryType === 'mark') {
        const workfrontIframeStartTime =
          shellMetrics?.loadTimes?.iframeFetchStart ?? 0

        if (workfrontIframeStartTime !== 0) {
          metricName = `shell_${metric.name}`
          metricValue = metric.startTime + workfrontIframeStartTime
        } else {
          metricValue = metric.startTime
        }
      } else {
        metricValue = metric.duration
      }

      window.DD_RUM.onReady(() => {
        window.DD_RUM.addAction(metricName, { [metricName]: metricValue })
      })
    }
  }
}
