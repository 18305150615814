import { initalizeDataDogGlobalContexts } from './bootstrap/datadog'
import {
  getLastNavigationEventTime,
  logCustomPageTiming,
  logPageLoadTime,
  logMetricToConsole,
  reportUserNavigationForPerformanceMetrics,
  resetPerformanceState,
  useCustomPerformanceMetrics,
} from './utilities/useCustomPerformanceMetrics.js'
export { MFELoggerAndReactErrorBoundary } from './log/loggerAndBoundary.js'

export const customPerformanceMetrics = {
  getLastNavigationEventTime,
  logCustomPageTiming,
  logPageLoadTime,
  logMetricToConsole,
  reportUserNavigationForPerformanceMetrics,
  resetPerformanceState,
  useCustomPerformanceMetrics,
}
export { createMFELogger } from './log/logger.js'
export { logPerformanceMetric } from './utilities/logPerformanceMetric.js'
export { logRumEvent } from './utilities/logRumEvent.js'
export {
  configureLaunchTrackFunction,
  // For backwards compatibility
  trackLaunchCustomEvent as trackLaunchEvent,
  trackLaunchCustomEvent,
  trackLaunchNetworkEvent,
} from './utilities/trackLaunchEvent.js'
export { initalizeDataDogGlobalContexts }
export { ErrorBoundary, ErrorBoundaryCompatChanges } from './ErrorBoundary.js'
export { useErrorBoundary } from 'react-error-boundary'
export const initializeLogger = initalizeDataDogGlobalContexts
